import React from "react";
import { Form, Row, Col } from "react-bootstrap";
import { Card, CardBody } from "../../../_metronic/_partials/controls";
import classNames from "classnames";
import { useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { getTransactions } from "../../../store/actions";

const SearchTransaction = (props) => {
  const {
    register,
    formState: { errors },
    reset,
  } = useFormContext({ reValidateMode: "onChange" });
  const intl = useIntl();
  const dispatch = useDispatch();

  const statusFilterList = [
    {
      value: "PENDING",
      label: intl.formatMessage({ id: "transaction.search.pending" }),
    },
    {
      value: "SUCCESS",
      label: intl.formatMessage({ id: "transaction.search.success" }),
    },
    {
      value: "FAIL",
      label: intl.formatMessage({ id: "transaction.search.fail" }),
    },
    {
      value: "REFUNDED",
      label: intl.formatMessage({ id: "transaction.search.refunded" }),
    },
  ];

  const clearSearchData = () => {
    dispatch(getTransactions(1));
    reset();
  };

  return (
    <>
      <Card>
        <CardBody>
          <Row className="justify-content-md-center">
            <Col md={12}>
              <Row className="form-group">
                <Col md={4}>
                  <Form.Control name="campaign" placeholder="Search By Project" className={classNames("form-control")} {...register("campaign")} />
                </Col>
                <Col md={4}>
                  <Form.Control name="investor" placeholder="Search By Investor" className={classNames("form-control")} {...register("investor")} />
                </Col>
                <Col md={4}>
                  <Form.Control name="transaction" placeholder="Search By Transaction ID" className={classNames("form-control")} {...register("transaction")} />
                </Col>
              </Row>
              <Row className="form-group">
                <Col md={4}>
                  <Form.Control
                    name="minamount"
                    placeholder="Search By Min Amount"
                    className={classNames("form-control", {
                      "is-invalid": errors.minamount,
                    })}
                    {...register("minamount", {
                      pattern: {
                        value: /[0-9]/,
                        message: "Minimum amount should be positive number",
                      },
                    })}
                  />
                  <ErrorMessage errors={errors} name="minamount" render={({ message }) => <div className="invalid-feedback">{message}</div>} />
                </Col>
                <Col md={4}>
                  <Form.Control
                    name="maxamount"
                    placeholder="Search By Max Amount"
                    className={classNames("form-control", {
                      "is-invalid": errors.maxamount,
                    })}
                    {...register("maxamount", {
                      pattern: {
                        value: /[0-9]/,
                        message: "Maximum amount should be positive number",
                      },
                    })}
                  />
                  <ErrorMessage errors={errors} name="maxamount" render={({ message }) => <div className="invalid-feedback">{message}</div>} />
                </Col>
                <Col md={4}>
                  <select placeholder={intl.formatMessage({ id: "transaction.search.status" })} className="form-control" {...register("preapprovalStatus")}>
                    <option defaultChecked value="">
                      {intl.formatMessage({ id: "transaction.search.status" })}
                    </option>
                    {statusFilterList.map((option, key) => (
                      <option key={key} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </Col>
              </Row>
              <Row className="form-group">
                <Col md={1}>
                  <button className="btn btn-primary">Search</button>
                </Col>
                <Col md={1}>
                  <button className="btn btn-secondary" type="button" onClick={() => clearSearchData()}>
                    Clear
                  </button>
                </Col>
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default SearchTransaction;
