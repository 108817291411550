/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { useDispatch, useSelector } from "react-redux";
import { getPermission } from "../../../../../store/actions/roleManagement/roleActions";
import * as actions from "../../../../../store/actions/index";
import { useIntl } from "react-intl";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const intl = useIntl();
  const getMenuItemActive = (url) => {
    return checkIsActive(location, url) ? " menu-item-active menu-item-open " : "";
  };
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPermission(localStorage.getItem("role")));
    dispatch(actions.getManageOptionsLanguages());
  }, [dispatch]);
  const { currentPermissions } = useSelector((state) => state.role);
  const { generalSettings } = useSelector((state) => state.settings);

  const { manageOptionsLanguages } = useSelector((state) => state.manageOptions);

  const setLanguageOnSubMenuChange = () => {
    dispatch(actions.setManageOptionsCurrentId(manageOptionsLanguages?.find((lng) => lng.isDefault === true).id));
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        {currentPermissions.list_dashboard === 1 ? (
          <li className={`menu-item ${getMenuItemActive("/dashboard")}`} aria-haspopup="true">
            <NavLink className="menu-link" id="menuDashboard" to="/dashboard">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
              </span>
              <span className="menu-text">Dashboard</span>
            </NavLink>
          </li>
        ) : null}

        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li className={`menu-item menu-item-submenu ${getMenuItemActive("/user-management")}`} aria-haspopup="true" data-menu-toggle="hover">
          <NavLink className="menu-link menu-toggle" id="menuDashboard" to="/user-management">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Group.svg")} />
            </span>
            <span className="menu-text">User Management</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <ul className="menu-subnav">
              <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">User Management</span>
                </span>
              </li>

              {/*begin::2 Level*/}
              {currentPermissions.list_admin_user === 1 ? (
                <li className={`menu-item ${getMenuItemActive("/user-management/admin")}`} aria-haspopup="true">
                  <NavLink className="menu-link" id="subMenuAdminUsers" to="/user-management/admin">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Admin Users</span>
                  </NavLink>
                </li>
              ) : null}
              {currentPermissions.list_front_user === 1 ? (
                <li className={`menu-item ${getMenuItemActive("/user-management/front-end-users")}`} aria-haspopup="true">
                  <NavLink className="menu-link" id="subMenuFrontEndUsers" to="/user-management/front-end-users">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Front-End Users</span>
                  </NavLink>
                </li>
              ) : null}
              {currentPermissions.list_role_management === 1 ? (
                <li className={`menu-item ${getMenuItemActive("/user-management/role-management")}`} aria-haspopup="true">
                  <NavLink className="menu-link" id="subMenuRoleManagement" to="/user-management/role-management">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Role Management</span>
                  </NavLink>
                </li>
              ) : null}
              {currentPermissions.list_accreditation === 1 ? (
                <li className={`menu-item ${getMenuItemActive("/user-management/accreditation")}`} aria-haspopup="true">
                  <NavLink className="menu-link" id="subMenuAccreditationUsers" to="/user-management/accreditation">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Accreditation Users</span>
                  </NavLink>
                </li>
              ) : null}
            </ul>
          </div>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li className={`menu-item menu-item-submenu ${getMenuItemActive("/campaign")}`} aria-haspopup="true" data-menu-toggle="hover">
          <NavLink className="menu-link menu-toggle" id="menuCampaign" to="/campaign">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Project Management</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <ul className="menu-subnav">
              <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Project</span>
                </span>
              </li>

              {/*begin::2 Level*/}
              {currentPermissions?.list_campaigns === 1 ? (
                <li className={`menu-item ${getMenuItemActive("/campaign/campaigns")}`} aria-haspopup="true">
                  <NavLink className="menu-link" id="subMenuCampaigns" to="/campaign/campaigns">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Projects</span>
                  </NavLink>
                </li>
              ) : null}

              {currentPermissions.list_categories === 1 ? (
                <li className={`menu-item ${getMenuItemActive("/campaign/categories")}`} aria-haspopup="true">
                  <NavLink className="menu-link" id="subMenuCategories" to="/campaign/categories" onClick={() => setLanguageOnSubMenuChange()}>
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Categories</span>
                  </NavLink>
                </li>
              ) : null}
            </ul>
          </div>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li className={`menu-item menu-item-submenu ${getMenuItemActive("/api-management")}`} aria-haspopup="true" data-menu-toggle="hover">
          <NavLink className="menu-link menu-toggle" id="menuApiManagement" to="/api-management">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Tools/Tools.svg")} />
            </span>
            <span className="menu-text">API Management</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <ul className="menu-subnav">
              <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">API Management</span>
                </span>
              </li>

              {/*begin::2 Level*/}
              {currentPermissions.list_payment_gateways === 1 ? (
                <li className={`menu-item ${getMenuItemActive("/api-management/payment-gateway")}`} aria-haspopup="true">
                  <NavLink className="menu-link" id="subMenuPaymentGateway" to="/api-management/payment-gateway">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Payment Gateway</span>
                  </NavLink>
                </li>
              ) : null}
              {currentPermissions.list_kyc_settings === 1 ? (
                <li className={`menu-item ${getMenuItemActive("/api-management/kyc-settings")}`} aria-haspopup="true">
                  <NavLink className="menu-link" id="subMenuKYCSettings" to="/api-management/kyc-settings">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">KYC Settings</span>
                  </NavLink>
                </li>
              ) : null}

              {currentPermissions.list_social_signup === 1 ? (
                <li className={`menu-item ${getMenuItemActive("/api-management/social-signup")}`} aria-haspopup="true">
                  <NavLink className="menu-link" id="subMenuSocialSetup" to="/api-management/social-signup">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Social Setup</span>
                  </NavLink>
                </li>
              ) : null}
              {currentPermissions.list_reCaptcha === 1 ? (
                <li className={`menu-item ${getMenuItemActive("/api-management/recaptcha")}`} aria-haspopup="true">
                  <NavLink className="menu-link" id="subMenuReCaptcha" to="/api-management/recaptcha">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">reCaptcha</span>
                  </NavLink>
                </li>
              ) : null}

              {currentPermissions.list_google_map === 1 ? (
                <li className={`menu-item ${getMenuItemActive("/api-management/google-map")}`} aria-haspopup="true">
                  <NavLink className="menu-link" id="subMenuGoogleMap" to="/api-management/google-map">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Google Map</span>
                  </NavLink>
                </li>
              ) : null}
              {currentPermissions.list_newsletter === 1 ? (
                <li className={`menu-item ${getMenuItemActive("/api-management/newsletter")}`} aria-haspopup="true">
                  <NavLink className="menu-link" id="subMenuNewsletterSettings" to="/api-management/newsletter">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Newsletter Settings</span>
                  </NavLink>
                </li>
              ) : null}
            </ul>
          </div>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        {currentPermissions.list_transaction === 1 ? (
          <li className={`menu-item ${getMenuItemActive("/transaction")}`} aria-haspopup="true">
            <NavLink className="menu-link" id="menuTransactions" to="/transaction">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Money.svg")} />
              </span>
              <span className="menu-text">Transactions</span>
            </NavLink>
          </li>
        ) : null}

        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        {generalSettings?.walletModule === "yes" ? (
          <li className={`menu-item ${getMenuItemActive("/wallet")}`} aria-haspopup="true">
            <NavLink className="menu-link menu-toggle" id="menuWallet" to="/wallet">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Wallet.svg")} />
              </span>
              <span className="menu-text">Wallet</span>
              <i className="menu-arrow" />
            </NavLink>
            <div className="menu-submenu ">
              <ul className="menu-subnav">
                <li className="menu-item  menu-item-parent" aria-haspopup="true">
                  <span className="menu-link">
                    <span className="menu-text">Wallet</span>
                  </span>
                </li>

                <li className={`menu-item ${getMenuItemActive("/wallet/wallet-transactions")}`} aria-haspopup="true">
                  <NavLink className="menu-link" id="subMenuWalletTransactions" to="/wallet/wallet-transactions">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Wallet Transactions</span>
                  </NavLink>
                </li>

                <li className={`menu-item ${getMenuItemActive("/wallet/topup-requests")}`} aria-haspopup="true">
                  <NavLink className="menu-link" id="subMenuTopupRequests" to="/wallet/topup-requests">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Top-up Requests</span>
                  </NavLink>
                </li>

                <li className={`menu-item ${getMenuItemActive("/wallet/withdraw-requests")}`} aria-haspopup="true">
                  <NavLink className="menu-link" id="subMenuWithdrawRequests" to="/wallet/withdraw-requests">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Withdraw Requests</span>
                  </NavLink>
                </li>
              </ul>
            </div>
          </li>
        ) : null}
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li className={`menu-item menu-item-submenu ${getMenuItemActive("/content-page")}`} aria-haspopup="true" data-menu-toggle="hover">
          <NavLink className="menu-link menu-toggle" id="menuContentPages" to="/content-page">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Files/File.svg")} />
            </span>
            <span className="menu-text">Content Pages</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <ul className="menu-subnav">
              <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Content Pages</span>
                </span>
              </li>

              {/*begin::2 Level*/}
              {currentPermissions.list_pages === 1 ? (
                <li className={`menu-item ${getMenuItemActive("/content-page/pages")}`} aria-haspopup="true">
                  <NavLink className="menu-link" id="subMenuPages" to="/content-page/pages" onClick={() => setLanguageOnSubMenuChange()}>
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Pages</span>
                  </NavLink>
                </li>
              ) : null}

              {true ? (
                <li className={`menu-item ${getMenuItemActive("/content-page/investor-contracts")}`} aria-haspopup="true">
                  <NavLink className="menu-link" id="subMenuPages" to="/content-page/investor-contracts" onClick={() => setLanguageOnSubMenuChange()}>
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Investor Contracts</span>
                  </NavLink>
                </li>
              ) : null}

              {currentPermissions.list_menu === 1 ? (
                <li className={`menu-item ${getMenuItemActive("/content-page/menu")}`} aria-haspopup="true">
                  <NavLink className="menu-link" id="subMenuMenu" to="/content-page/menu" onClick={() => setLanguageOnSubMenuChange()}>
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Menu</span>
                  </NavLink>
                </li>
              ) : null}

              {currentPermissions.list_faqs === 1 ? (
                <li className={`menu-item ${getMenuItemActive("/content-page/faqs")}`} aria-haspopup="true">
                  <NavLink className="menu-link" id="subMenuFaqs" to="/content-page/faqs" onClick={() => setLanguageOnSubMenuChange()}>
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">FAQs</span>
                  </NavLink>
                </li>
              ) : null}

              {currentPermissions.list_front_end_faqs === 1 ? (
                <li className={`menu-item ${getMenuItemActive("/content-page/front-end-faqs")}`} aria-haspopup="true">
                  <NavLink className="menu-link" id="subMenuFrontFaqs" to="/content-page/front-end-faqs" onClick={() => setLanguageOnSubMenuChange()}>
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Front-End FAQs</span>
                  </NavLink>
                </li>
              ) : null}

              {/* {currentPermissions.list_help_center === 1 ? (
                  <li className={`menu-item ${getMenuItemActive("/content-page/help-center")}`} aria-haspopup="true">
                    <NavLink className="menu-link" id="subMenuHelpCenter" to="/content-page/help-center">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Help Center</span>
                    </NavLink>
                  </li>
                ) : null}
                {currentPermissions.list_help_category === 1 ? (
                  <li
                    className={`menu-item ${getMenuItemActive("/content-page/help-categories")}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" id="subMenuHelpCategory" to="/content-page/help-categories">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Help Category</span>
                    </NavLink>
                  </li>
                ) : null} */}
            </ul>
          </div>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li className={`menu-item menu-item-submenu ${getMenuItemActive("/manage-options")}`} aria-haspopup="true" data-menu-toggle="hover">
          <NavLink className="menu-link menu-toggle" id="menuManageOptions" to="/manage-options">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Commode1.svg")} />
            </span>
            <span className="menu-text">Manage Options</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <ul className="menu-subnav">
              <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Manage Options</span>
                </span>
              </li>

              {/*begin::2 Level*/}
              {currentPermissions.list_deal_type === 1 ? (
                <li className={`menu-item ${getMenuItemActive("/manage-options/deal-type")}`} aria-haspopup="true">
                  <NavLink className="menu-link" id="subMenuDealTypes" to="/manage-options/deal-type">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Deal Types</span>
                  </NavLink>
                </li>
              ) : null}

              {currentPermissions.list_company_industry === 1 ? (
                <li className={`menu-item ${getMenuItemActive("/manage-options/company-industry")}`} aria-haspopup="true">
                  <NavLink className="menu-link" id="subMenuCompanyIndustry" to="/manage-options/company-industry" onClick={() => setLanguageOnSubMenuChange()}>
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Company Industry</span>
                  </NavLink>
                </li>
              ) : null}
              {/* {currentPermissions.list_investing_source === 1 ? (
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/manage-options/investing-source"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink
                    className="menu-link"
                    id="subMenuInvestingSource"
                    to="/manage-options/investing-source"
                  >
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Investing Source</span>
                  </NavLink>
                </li>
              ) : null} */}
              {/* {currentPermissions.list_investing_type === 1 ? (
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/manage-options/investing-type"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink
                    className="menu-link"
                    id="subMenuInvestingType"
                    to="/manage-options/investing-type"
                  >
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Investing Type</span>
                  </NavLink>
                </li>
              ) : null} */}

              {/* {currentPermissions.list_document_type === 1 ? (
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/manage-options/document-type"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink
                    className="menu-link"
                    id="subMenuDocumentType"
                    to="/manage-options/document-type"
                  >
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Document Type</span>
                  </NavLink>
                </li>
              ) : null} */}

              {currentPermissions.list_team_member_type === 1 ? (
                <li className={`menu-item ${getMenuItemActive("/manage-options/team-member-type")}`} aria-haspopup="true">
                  <NavLink className="menu-link" id="subMenuTeamMemberType" to="/manage-options/team-member-type" onClick={() => setLanguageOnSubMenuChange()}>
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Team Member Type</span>
                  </NavLink>
                </li>
              ) : null}
              {currentPermissions.list_investor_type === 1 ? (
                <li className={`menu-item ${getMenuItemActive("/manage-options/investor-type")}`} aria-haspopup="true">
                  <NavLink className="menu-link" id="subMenuInvestorType" to="/manage-options/investor-type" onClick={() => setLanguageOnSubMenuChange()}>
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Investor Type</span>
                  </NavLink>
                </li>
              ) : null}
              {currentPermissions.list_campaign_stage === 1 ? (
                <li className={`menu-item ${getMenuItemActive("/manage-options/campaign-stage")}`} aria-haspopup="true">
                  <NavLink className="menu-link" id="subMenuStage" to="/manage-options/campaign-stage" onClick={() => setLanguageOnSubMenuChange()}>
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">{intl.formatMessage({ id: `${generalSettings?.projectScriptType === 0 ? "CAMPAIGN.STAGE" : "PROPERTY.STAGE"}` })}</span>
                  </NavLink>
                </li>
              ) : null}

              {currentPermissions.list_investing_round === 1 ? (
                <li className={`menu-item ${getMenuItemActive("/manage-options/investing-round")}`} aria-haspopup="true">
                  <NavLink className="menu-link" id="subMenuInvestingRound" to="/manage-options/investing-round" onClick={() => setLanguageOnSubMenuChange()}>
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Investing Round</span>
                  </NavLink>
                </li>
              ) : null}
            </ul>
          </div>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li className={`menu-item menu-item-submenu ${getMenuItemActive("/miscellaneous")}`} aria-haspopup="true" data-menu-toggle="hover">
          <NavLink className="menu-link menu-toggle" id="menuMiscellaneous" to="/miscellaneous">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Files/Group-folders.svg")} />
            </span>
            <span className="menu-text">Miscellaneous</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <ul className="menu-subnav">
              <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Miscellaneous</span>
                </span>
              </li>

              {/*begin::2 Level*/}
              {currentPermissions.list_countries === 1 ? (
                <li className={`menu-item ${getMenuItemActive("/miscellaneous/countries")}`} aria-haspopup="true">
                  <NavLink className="menu-link" id="subMenuCountries" to="/miscellaneous/countries">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Countries</span>
                  </NavLink>
                </li>
              ) : null}
              {currentPermissions.list_currency === 1 ? (
                <li className={`menu-item ${getMenuItemActive("miscellaneous/currency")}`} aria-haspopup="true">
                  <NavLink className="menu-link" id="subMenuCurrency" to="/miscellaneous/currency">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Currency</span>
                  </NavLink>
                </li>
              ) : null}
              {currentPermissions.list_cron_jobs === 1 ? (
                <li className={`menu-item ${getMenuItemActive("/miscellaneous/ron-jobs")}`} aria-haspopup="true">
                  <NavLink className="menu-link" id="subMenuCronJobs" to="/miscellaneous/cron-jobs">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Cron Jobs</span>
                  </NavLink>
                </li>
              ) : null}

              {/* {currentPermissions.list_version_update === 1 ? (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/miscellaneous/version-updates"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink
                      className="menu-link"
                      id="subMenuVersionUpdates"
                      to="/miscellaneous/version-updates"
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Version Updates</span>
                    </NavLink>
                  </li>
                ) : null} */}

              {currentPermissions.list_language === 1 ? (
                <li className={`menu-item ${getMenuItemActive("/miscellaneous/language")}`} aria-haspopup="true">
                  <NavLink className="menu-link" id="subMenuLanguage" to="/miscellaneous/language">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Language</span>
                  </NavLink>
                </li>
              ) : null}
              {currentPermissions.list_sliders === 1 ? (
                <li className={`menu-item ${getMenuItemActive("/miscellaneous/sliders")}`} aria-haspopup="true">
                  <NavLink className="menu-link" id="subMenuSliders" to="/miscellaneous/sliders" onClick={() => setLanguageOnSubMenuChange()}>
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Sliders</span>
                  </NavLink>
                </li>
              ) : null}
            </ul>
          </div>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li className={`menu-item menu-item-submenu ${getMenuItemActive("/settings")}`} aria-haspopup="true" data-menu-toggle="hover">
          <NavLink className="menu-link menu-toggle" id="menuSettings" to="/settings">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Settings-2.svg")} />
            </span>
            <span className="menu-text">Settings</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <ul className="menu-subnav">
              <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Settings</span>
                </span>
              </li>

              {/*begin::2 Level*/}
              {currentPermissions.list_general_setting === 1 ? (
                <li className={`menu-item ${getMenuItemActive("/settings/general")}`} aria-haspopup="true">
                  <NavLink className="menu-link" id="subMenuGeneral" to="/settings/general">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">General</span>
                  </NavLink>
                </li>
              ) : null}
              {currentPermissions.list_campaign_setting === 1 ? (
                <li className={`menu-item ${getMenuItemActive("/settings/settings-campaign")}`} aria-haspopup="true">
                  <NavLink className="menu-link" id="subMenuCampaign" to="/settings/settings-campaign">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Project Settings</span>
                  </NavLink>
                </li>
              ) : null}
              {currentPermissions.list_revenue_setting === 1 ? (
                <li className={`menu-item ${getMenuItemActive("/settings/revenue")}`} aria-haspopup="true">
                  <NavLink className="menu-link" id="subMenuRevenue" to="/settings/revenue">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Revenue</span>
                  </NavLink>
                </li>
              ) : null}

              {currentPermissions.list_datetime_setting === 1 ? (
                <li className={`menu-item ${getMenuItemActive("/settings/date-time")}`} aria-haspopup="true">
                  <NavLink className="menu-link" id="subMenuDateAndTime" to="/settings/date-time">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Date and Time</span>
                  </NavLink>
                </li>
              ) : null}
              {currentPermissions.list_meta_setting === 1 ? (
                <li className={`menu-item ${getMenuItemActive("/settings/meta")}`} aria-haspopup="true">
                  <NavLink className="menu-link" id="subMenuMeta" to="/settings/meta" onClick={() => setLanguageOnSubMenuChange()}>
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Meta</span>
                  </NavLink>
                </li>
              ) : null}
              {currentPermissions.list_meta_setting === 1 ? (
                <li className={`menu-item ${getMenuItemActive("/settings/amount")}`} aria-haspopup="true">
                  <NavLink className="menu-link" id="subMenuAmount" to="/settings/amount">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Amount</span>
                  </NavLink>
                </li>
              ) : null}
              {currentPermissions.list_email_setting === 1 ? (
                <li className={`menu-item ${getMenuItemActive("/settings/email-templates")}`} aria-haspopup="true">
                  <NavLink className="menu-link" id="subMenuEmail" to="/settings/email-templates">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Email</span>
                  </NavLink>
                </li>
              ) : null}
            </ul>
          </div>
        </li>
        {/*end::1 Level*/}

        <li className={`menu-item menu-item-submenu ${getMenuItemActive("/spam-settings")}`} aria-haspopup="true" data-menu-toggle="hover">
          <NavLink className="menu-link menu-toggle" id="menuSpamSetting" to="/spam-settings">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Settings-1.svg")} />
            </span>
            <span className="menu-text">Spam Settings</span>
            {/* <i className="menu-arrow" /> */}
          </NavLink>
          {/* <div className="menu-submenu ">
            <ul className="menu-subnav">
              <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Spam Setting</span>
                </span>
              </li>

              {currentPermissions.list_spam_report === 1 ? (
                <li className={`menu-item ${getMenuItemActive("/spam-settings/spam-report")}`} aria-haspopup="true">
                  <NavLink className="menu-link" id="subMenuSpamReport" to="/spam-settings/spam-report">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Spam Report</span>
                  </NavLink>
                </li>
              ) : null}
              {currentPermissions.list_spammers === 1 ? (
                <li className={`menu-item ${getMenuItemActive("/spam-settings/spammer")}`} aria-haspopup="true">
                  <NavLink className="menu-link" id="subMenuSpammer" to="/spam-settings/spammer">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Spammer</span>
                  </NavLink>
                </li>
              ) : null}
              {currentPermissions.list_spam_setting === 1 ? (
                <li className={`menu-item ${getMenuItemActive("/spam-settings/setting")}`} aria-haspopup="true">
                  <NavLink className="menu-link" id="subMenuSetting" to="/spam-settings/setting">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Setting</span>
                  </NavLink>
                </li>
              ) : null}
            </ul>
          </div> */}
        </li>
        {currentPermissions.list_contact_enquiry === 1 ? (
          <li className={`menu-item ${getMenuItemActive("/contact-list")}`} aria-haspopup="true">
            <NavLink className="menu-link" id="menuContactEnquiry" to="/contact-list">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Group.svg")} />
              </span>
              <span className="menu-text">Contact Enquiry</span>
            </NavLink>
          </li>
        ) : null}

        {/*begin::1 Level*/}
        {currentPermissions.list_newsletters === 1 ? (
          <li className={`menu-item ${getMenuItemActive("/newsletter")}`} aria-haspopup="true">
            <NavLink className="menu-link" id="menuNewsletters" to="/newsletter">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Media/Airplay.svg")} />
              </span>
              <span className="menu-text">Newsletters</span>
            </NavLink>
          </li>
        ) : null}

        {currentPermissions.maintenance_mode === 1 ? (
          <li className={`menu-item ${getMenuItemActive("/maintenance-mode")}`} aria-haspopup="true">
            <NavLink className="menu-link" id="menuMaintenanceMode" to="/maintenance-mode">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/General/Settings-2.svg")} />
              </span>
              <span className="menu-text">Maintenance Mode</span>
            </NavLink>
          </li>
        ) : null}
      </ul>
    </>
  );
}
