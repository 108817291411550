/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import { isUndefined } from "lodash";
import moment from "moment";
import React, { useEffect, useState, useRef } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { formatCurrency } from "../../../../helpers/numberFormat";
import { approveCampaign, declineCampaign, getCampaigns } from "../../../../store/actions/campaigns/campaignsAction";
import { getDashboardPendingCampaigns } from "../../../../store/actions/dashboard/dashboardActions";
import { toAbsoluteUrl } from "../../../_helpers";
import { toast, ToastContainer } from "react-toastify";
import { toastifyConstant } from "../../../../helpers/helpers";
import { getSiteBaseURL, getDemoMode } from "../../../../helpers";
import * as actions from "../../../../store/actions/index";

export function AdvanceTablesWidget2({ className }) {
  let demoMode = getDemoMode();
  const siteBaseUrl = useRef(getSiteBaseURL());
  const dispatch = useDispatch();
  const history = useHistory();
  const swal = withReactContent(Swal);

  useEffect(() => {
    dispatch(getDashboardPendingCampaigns(1));
    dispatch(getCampaigns(1));
    dispatch(actions.getCampaignSettings());
  }, [dispatch]);
  const { campaignsSettings } = useSelector((state) => state.settings);
  const viewDetails = (id) => {
    history.push(`/campaign/campaign-details/${id}`);
  };

  const { pendingCampaign } = useSelector((state) => state.dashboard);
  const { campaigns } = useSelector((state) => state.campaign);
  const { generalSettings } = useSelector((state) => state.settings);

  const swalAction = (title, text, icon, action, id) => {
    swal
      .fire({
        title: title,
        text: text,
        icon: icon,
        confirmButtonText: "Yes",
        showCancelButton: true,
        cancelButtonText: "Cancel",
        allowOutsideClick: false,
        allowEscapeKey: false,
      })
      .then((result) => {
        if (result["isConfirmed"]) {
          dispatch(action(id));
        }
      });
  };
  const notifyUpdate = (message, toastId) => {
    toast.error(message, { toastifyConstant, toastId: toastId });
  };

  const pendingCampaignList =
    pendingCampaign?.docs?.length > 0 ? (
      pendingCampaign?.docs?.map((pending, key) => (
        <tr key={key}>
          <td>
            <div className="d-flex align-items-center">
              <div className="symbol symbol-30 mr-4">
                <img className="symbol-label" alt="user" src={toAbsoluteUrl(`/media/stock-600x400/img-45.jpg`)} />
              </div>
              <div>
                {isUndefined(pending.companyId?.companyName) ? (
                  "-"
                ) : (
                  <a href={`${siteBaseUrl.current}campaign-detail-page/${pending.companyId?.companySlug}`} className="font-weight-bolder" target="_blank">
                    {pending.companyId?.companyName ?? "-"}
                  </a>
                )}
              </div>
            </div>
          </td>
          <td>
            <span>
              <a href={`${siteBaseUrl.current}profile/${pending.user?.slug}`} target="_blank">
                {pending.user?.firstName} {pending.user?.lastName}
              </a>
            </span>
          </td>
          <td>
            <span>
              {formatCurrency(
                pending.goal,
                generalSettings.currencySymbolSide,
                pending.equityCurrencySymbol ?? generalSettings.currencyId?.symbol,
                pending.equityCurrencyCode ?? generalSettings.currencyId?.code,
                generalSettings.decimalPoints
              ) ?? "-"}
            </span>
          </td>
          <td>
            <span>{moment(pending.createdAt).format(generalSettings.dateFormat) ?? "-"}</span>
          </td>
          <td>
            <span>{moment(pending.closingDate).format(generalSettings.dateFormat) ?? "-"}</span>
          </td>
          {demoMode === "false" ? (
            <td>
              <OverlayTrigger
                popperConfig={{
                  modifiers: {
                    preventOverflow: {
                      enabled: false,
                    },
                    hide: {
                      enabled: false,
                    },
                  },
                }}
                show={false}
                placement="bottom"
                overlay={<Tooltip>View</Tooltip>}
              >
                <button
                  onClick={() => {
                    viewDetails(pending._id);
                  }}
                  className="btn btn-icon btn-light btn-sm"
                >
                  <span className="svg-icon svg-icon-md svg-icon-primary">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/General/Visible.svg")} />
                  </span>
                </button>
              </OverlayTrigger>
              {campaignsSettings?.contractCopy === true && campaignsSettings?.manageContract === "no" && pending?.uploadDocumentContract === undefined ? (
                <OverlayTrigger
                  popperConfig={{
                    modifiers: {
                      preventOverflow: {
                        enabled: false,
                      },
                    },
                  }}
                  placement="bottom"
                  overlay={<Tooltip>Please upload the contract document then activate your project!</Tooltip>}
                >
                  <Link to={`/campaign/campaign-details/${pending?._id}`} className="btn btn-icon btn-light btn-hover-success btn-sm ml-3">
                    <span className="svg-icon svg-icon-md svg-icon-success">
                      <SVG title=" " src={toAbsoluteUrl("/media/svg/icons/Navigation/Check.svg")} />
                    </span>
                  </Link>
                </OverlayTrigger>
              ) : (
                <>
                  {pending?.status === 2 || pending?.status === 4 || pending?.status === 5 ? (
                    <OverlayTrigger
                      popperConfig={{
                        modifiers: {
                          preventOverflow: {
                            enabled: false,
                          },
                        },
                      }}
                      placement="bottom"
                      overlay={<Tooltip>Approved</Tooltip>}
                    >
                      <button disabled className="btn btn-icon btn-light btn-hover-success btn-sm ml-3">
                        <span className="svg-icon svg-icon-md svg-icon-success">
                          <SVG title=" " src={toAbsoluteUrl("/media/svg/icons/Navigation/Check.svg")} />
                        </span>
                      </button>
                    </OverlayTrigger>
                  ) : (
                    <OverlayTrigger
                      popperConfig={{
                        modifiers: {
                          preventOverflow: {
                            enabled: false,
                          },
                        },
                      }}
                      placement="bottom"
                      overlay={<Tooltip>Approve</Tooltip>}
                    >
                      <button
                        onClick={() => {
                          new Date().getTime() > new Date(pending?.closingDate).getTime()
                            ? notifyUpdate("Closing date is Invalid, ask the project owner to update the closing date", "closindDateError")
                            : swalAction("Confirm ?", "Are you sure you want to approve this project?", "success", approveCampaign, pending?._id);
                        }}
                        className="btn btn-icon btn-light btn-hover-success btn-sm ml-3"
                      >
                        <span className="svg-icon svg-icon-md svg-icon-success">
                          <SVG title=" " src={toAbsoluteUrl("/media/svg/icons/Navigation/Check.svg")} />
                        </span>
                      </button>
                    </OverlayTrigger>
                  )}
                </>
              )}
              <OverlayTrigger
                popperConfig={{
                  modifiers: {
                    preventOverflow: {
                      enabled: false,
                    },
                    hide: {
                      enabled: false,
                    },
                  },
                }}
                placement="bottom"
                overlay={<Tooltip>Decline</Tooltip>}
              >
                <button
                  onClick={() => {
                    swalAction("Decline", "Confirm Decline of the project ?", "error", declineCampaign, pending._id);
                  }}
                  title="Decline"
                  className="btn btn-icon btn-light btn-hover-danger btn-sm"
                >
                  <span className="svg-icon svg-icon-md svg-icon-danger">
                    <SVG title=" " src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")} />
                  </span>
                </button>
              </OverlayTrigger>
            </td>
          ) : (
            ""
          )}
        </tr>
      ))
    ) : (
      <tr className="text-center">
        <td colSpan={6}>
          <p>{pendingCampaign?.docs?.length === 0 ? "No Record Found!" : "Loading..."}</p>
        </td>
      </tr>
    );
  return (
    <div className={`card card-custom ${className}`}>
      <ToastContainer />
      {/* Head */}
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label font-weight-bolder text-dark">Pending Projects</span>
          <span className="text-muted mt-3 font-weight-bold font-size-sm">Total Projects: {pendingCampaign?.totalCount}</span>
        </h3>
      </div>
      {/* Body */}
      <div className="card-body pt-3 pb-0">
        <div className="table-responsive">
          <table className="table-head-custom table-vertical-center table">
            <thead>
              <tr>
                <th>Project</th>
                <th>Creator</th>
                <th>Goal</th>
                <th>Post Date</th>
                <th>End Date</th>
                {demoMode === "false" ? <th>Action</th> : ""}
              </tr>
            </thead>
            <tbody>{pendingCampaignList}</tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
