import * as actionTypes from "../../actions/campaigns/actionTypes";
import { updateObject } from "../../utility/utility";

const initialState = {
  error: null,
  campaigns: [],
  totalReservations: [],
  campaignDetails: [],
  investors: [],
  documents: [],
  investor: [],
  activities: [],
  bonusPerk: [],
  repaymentIn: [],
  distribution: [],
  distributionDetail: [],
  PrivateCampaignUsers: [],
  isLoading: false,
  loading: true,
  resendEmailMessage: null,
  updatedCampaigns: [],
  achPaymentConfirmToken: null,
  achPaymentConfirmationId: null,
  addInviteSuccess: false,
};

const campaignStart = (state, action) => {
  return updateObject(state, {
    error: null,
    totalReservations: [],
    documents: [],
    investor: [],
    updatedCampaigns: [],
    achPaymentConfirmToken: null,
    achPaymentConfirmationId: null,
  });
};

const campaignFail = (state, action) => {
  return updateObject(state, {
    error: action?.error?.data,
  });
};

const getCampaigns = (state, action) => {
  return updateObject(state, {
    campaigns: action.response,
  });
};

const getPrivateCampaignsUsers = (state, action) => {
  return updateObject(state, {
    PrivateCampaignUsers: action.response,
  });
};

const resendEmailToCampaignUser = (state, action) => {
  return updateObject(state, {
    resendEmailMessage: action.response,
  });
};

const addPrivateCampaignsUsersSuccess = (state, action) => {
  return updateObject(state, {
    addInviteSuccess: true,
  });
};

const emptyEmailMessage = (state) => {
  return updateObject(state, {
    resendEmailMessage: null,
    addInviteSuccess: false,
  });
};

export const getTotalReservations = (state, action) => {
  return updateObject(state, {
    totalReservations: action.response,
  });
};

export const getCampaignDetails = (state, action) => {
  return updateObject(state, {
    campaignDetails: action.response,
  });
};

export const getInvestors = (state, action) => {
  return updateObject(state, {
    investors: action.response,
  });
};

export const getDocuments = (state, action) => {
  return updateObject(state, {
    documents: action.response,
  });
};

export const getInvestor = (state, action) => {
  return updateObject(state, {
    investor: action.response,
  });
};

export const getCampaignActivities = (state, action) => {
  return updateObject(state, {
    activities: action.response,
  });
};

export const getCampaignBonusPerks = (state, action) => {
  return updateObject(state, {
    bonusPerk: action.response,
  });
};

export const getRePaymentIn = (state, action) => {
  return updateObject(state, {
    repaymentIn: action.response,
  });
};

export const getDistribution = (state, action) => {
  return updateObject(state, {
    distribution: action.response,
  });
};

export const getDistributionDetail = (state, action) => {
  return updateObject(state, {
    distributionDetail: action.response,
    distributionData: action.response.distributionData,
    isLoading: false,
  });
};

export const isLoading = (state, action) => {
  return updateObject(state, {
    loading: action.response,
  });
};

export const updateCampaigns = (state, action) => {
  return updateObject(state, {
    updateCampaigns: action.response,
  });
};

const clearStripeACHPaymentToken = (state, action) => {
  return updateObject(state, {
    achPaymentConfirmToken: null,
  });
};

const getStripeACHPaymentTokenSuccess = (state, action) => {
  return updateObject(state, {
    achPaymentConfirmToken: action.response,
  });
};

const postStripeACHPaymentConfimationSuccess = (state, action) => {
  return updateObject(state, {
    achPaymentConfirmationId: action.response,
  });
};

const clearStripeACHPaymentConfimation = (state, action) => {
  return updateObject(state, {
    achPaymentConfirmationId: null,
  });
};

const clearOneTimeError = (state, action) => {
  return updateObject(state, {
    error: null,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CAMPAIGN_START:
      return campaignStart(state, action);
    case actionTypes.CAMPAIGN_FAIL:
      return campaignFail(state, action);
    case actionTypes.GET_CAMPAIGNS_SUCCESS:
      return getCampaigns(state, action);
    case actionTypes.GET_PRIVATE_CAMPAIGN_USERS_SUCCESS:
      return getPrivateCampaignsUsers(state, action);
    case actionTypes.RESEND_MAIL_TO_CAMPAIGN_USER_SUCCESS:
      return resendEmailToCampaignUser(state, action);
    case actionTypes.ADD_PRIVATE_CAMPAIGN_USER_SUCCESS:
      return addPrivateCampaignsUsersSuccess(state, action);
    case actionTypes.EMPTY_EMAIL_MSG:
      return emptyEmailMessage(state);
    case actionTypes.GET_TOTAL_RESERVARTIONS_SUCCESS:
      return getTotalReservations(state, action);
    case actionTypes.GET_CAMPAIGN_DETAILS_SUCCESS:
      return getCampaignDetails(state, action);
    case actionTypes.GET_INVESTORS_SUCCESS:
      return getInvestors(state, action);
    case actionTypes.GET_DOCUMENTS_SUCCESS:
      return getDocuments(state, action);
    case actionTypes.GET_INVESTOR_DETAILS_SUCCESS:
      return getInvestor(state, action);
    case actionTypes.GET_CAMPAIGN_ACTIVITIES_SUCCESS:
      return getCampaignActivities(state, action);
    case actionTypes.GET_CAMPAIGN_BONUS_PERKS_SUCCESS:
      return getCampaignBonusPerks(state, action);
    case actionTypes.GET_REPAYMENT_IN_SUCCESS:
      return getRePaymentIn(state, action);
    case actionTypes.GET_DISTRIBUTION_SUCCESS:
      return getDistribution(state, action);
    case actionTypes.GET_DISTRIBUTION_DETAIL_SUCCESS:
      return getDistributionDetail(state, action);
    case actionTypes.UPDATE_CAMPAIGN_SUCCESS:
      return updateCampaigns(state, action);
    case actionTypes.IS_LOADING:
      return isLoading(state, action);
    case actionTypes.GET_STRIPE_ACH_PAYMENT_TOKEN_SUCCESS:
      return getStripeACHPaymentTokenSuccess(state, action);
    case actionTypes.CLEAR_STRIPE_ACH_PAYMENT_TOKEN:
      return clearStripeACHPaymentToken(state, action);
    case actionTypes.POST_STRIPE_ACH_PAYMENT_CONFIMATION_SUCCESS:
      return postStripeACHPaymentConfimationSuccess(state, action);
    case actionTypes.CLEAR_STRIPE_ACH_PAYMENT_CONFIMATION:
      return clearStripeACHPaymentConfimation(state, action);
    case actionTypes.CLEAR_ONE_TIME_ERROR:
      return clearOneTimeError(state, action);
    default:
      return state;
  }
};

export default reducer;
