import { put } from "redux-saga/effects";
import { META_API } from "../../actions/apiCollections";
import axios from "../../../config/AxiosConfig";
import {
  metaStart,
  metaFail,
  getMetaSuccess,
  createMetaSuccess,
  updateMetaSuccess,
} from "../../actions/meta/metaActions";

export function* getMeta(action) {
  yield put(metaStart());
  try {
    const lang = action.language ? `?language=${action.language}` : "";
    const response = yield axios.get(META_API + lang);
    yield put(getMetaSuccess(response.data.data.data));
  } catch (error) {
    yield put(metaFail(error));
  }
}

export function* createMeta(action) {
  yield put(metaStart());
  try {
    const response = yield axios.post(META_API, action.meta);
    yield put(createMetaSuccess(response.data.data.data));
  } catch (error) {
    yield put(metaFail(error));
  }
}

export function* updateMeta(action) {
  yield put(metaStart());
  try {
    const response = yield axios.patch(
      META_API + action.meta.get("id"),
      action.meta
    );
    yield put(updateMetaSuccess(response.data.data.data));
  } catch (error) {
    yield put(metaFail(error));
  }
}
