export const getURL = () => {
  const url = document.location.href;
  return url.substring(url.lastIndexOf("/") + 1);
};

// AWS DEV EQUITY URL: https://equityfront.groupfund.me/
// AWS DEMO EQUITY URL: https://reactdemo.equityfundingscript.com/
// AWS DEV REAL ESTATE URL: https://realfront.groupfund.me/
// AWS DEMO REAL ESTATE URL: https://realestatefront.equityfundingscript.com/

export const getSiteBaseURL = () => {
  const siteUrl = `${process.env.REACT_APP_FRONT_BASE_URL}/`;
  return siteUrl;
};

export const getSiteHost = () => {
  // LOCAL:  localhost:3000
  // AWS DEV EQUITY ADMIN URL: https://equityadmin.groupfund.me
  // AWS DEMO EQUITY ADMIN URL: https://demoadmin.equityfundingscript.com
  // AWS DEV REAL ESTATE ADMIN URL: https://realadmin.groupfund.me
  // AWS LIVE REAL ESTATE ADMIN URL: https://realestateadmin.equityfundingscript.com

  const siteHost = `${process.env.REACT_APP_ADMIN_BASE_URL}`;
  return siteHost;
};

export const doDemoModeOnOff = () => {
  const password = getURL();
  if (password === "rockers137") {
    localStorage.setItem("DEMO_MODE", false);
  } else {
    const url = document.location.href;
    const siteHost = getSiteHost();
    if (url.lastIndexOf(siteHost) !== -1) {
      localStorage.setItem("DEMO_MODE", false);
    } else {
      localStorage.setItem("DEMO_MODE", false);
    }
  }
};

export function getDemoMode() {
  if (localStorage.getItem("DEMO_MODE") === null) {
    doDemoModeOnOff();
  }
  return localStorage.getItem("DEMO_MODE");
}

// AWS EQUITY DEV URL: https://d1623jjeo3lld4.cloudfront.net/
// AWS EQUITY DEMO URL: https://d13airn8ndl3pd.cloudfront.net/
// AWS DEV REAL ESTATE S3 URL: https://d1gauk4g1oie92.cloudfront.net/
// AWS LIVE REAL ESTATE S3 URL: https://d1x0dieh83herg.cloudfront.net/

export const getBaseImageURL = () => {
  const url = `${process.env.REACT_APP_AWS_URL}/`;
  return url;
};
