import React, { useRef, useEffect } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Card, CardBody } from "../../../_metronic/_partials/controls";
import classNames from "classnames";
import Select from "react-select";
import { useForm, useFormContext, Controller } from "react-hook-form";
import { authCheckState } from "../../../store/actions";

const SearchNewsletter = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(authCheckState());
  }, [dispatch]);

  const { register, control } = useFormContext();
  const subscription_type = useRef([
    {
      value: "",
      label: "Select Subscription Type",
    },
    {
      value: "newsletter",
      label: "Newsletter",
    },
    {
      value: "signup",
      label: "Signup",
    },
  ]);
  return (
    <>
      <Card>
        <CardBody>
          <Row className="justify-content-md-center">
            <Col md={12}>
              <Row className="form-group">
                <Col md={4}>
                  <Form.Control
                    name="search"
                    placeholder="Search By Name / Email"
                    className={classNames("form-control")}
                    {...register("search")}
                  />
                </Col>
                <Col md={4}>
                  <Controller
                    name={"subscriptionType"}
                    control={control}
                    render={({ field: { value, onChange } }) => {
                      return (
                        <Select
                          placeholder={"Select Subscription Type"}
                          classNamePrefix="react-select"
                          className={"react-select-container"}
                          options={subscription_type.current}
                          value={subscription_type.current.find(
                            (c) => c.value === value
                          )}
                          onChange={(val) => onChange(val.value)}
                        />
                      );
                    }}
                  />
                </Col>
                <Col md={4}>
                  <button className="btn btn-primary btn-hover-secondary btn-sm ml-3">
                    Search
                  </button>
                </Col>
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default SearchNewsletter;
