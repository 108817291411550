import React from "react";
import {
  MixedWidget1,
  ListsWidget9,
  StatsWidget1,
  StatsWidget11,
  StatsWidget12,
  AdvanceTablesWidget2,
} from "../widgets";
export function Demo1Dashboard() {
  return (
    <>
      <div className="col-xxl-12 order-2 order-xxl-1">
        <StatsWidget1 className="card-stretch gutter-b" />
      </div>
      <div className="row">
        <div className="col-lg-6 col-xxl-4">
          <MixedWidget1 className="card-stretch gutter-b" />
        </div>

        <div className="col-lg-6 col-xxl-4">
          <StatsWidget11 className="card-stretch gutter-b" />
        </div>

        <div className="col-lg-6 col-xxl-4 order-1 order-xxl-1">
          <StatsWidget12 className="card-stretch gutter-b" />
        </div>

        <div className="col-xxl-12 order-2 order-xxl-1">
          <AdvanceTablesWidget2 className="card-stretch gutter-b" />
        </div>

        <div className="col-xxl-12 order-2 order-xxl-1">
          <ListsWidget9 className="card-stretch gutter-b" />
        </div>
      </div>
    </>
  );
}
