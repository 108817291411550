import React, { useEffect } from "react";
import { Dashboard } from "../../_metronic/_partials";
import { useDispatch } from "react-redux";
import { authCheckState } from "../../store/actions";

export function DashboardPage() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(authCheckState());
  }, [dispatch]);

  return <Dashboard />;
}
