import React, { useEffect } from "react";
import { Redirect, Switch } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ContentRoute } from "../../../_metronic/layout";
import WalletTransactions from "./WalletTransactions/WalletTransactions";
import TopupRequests from "./TopupRequests/TopupRequests";
import WithdrawRequests from "./WithdrawRequests/WithdrawRequests";
import { authCheckState } from "../../../store/actions";

export default function WalletRouter() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(authCheckState());
  }, [dispatch]);
  return (
    <Switch>
      <Redirect exact={true} from="/wallet" to="/wallet/wallet-transactions" />

      <ContentRoute
        from="/wallet/wallet-transactions"
        component={WalletTransactions}
      />

      <ContentRoute from="/wallet/topup-requests" component={TopupRequests} />

      <ContentRoute
        from="/wallet/withdraw-requests"
        component={WithdrawRequests}
      />
    </Switch>
  );
}
