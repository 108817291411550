import axios from "axios";

// DEV EQUITY URL : https://equityapi.groupfund.me/api/v1.1/
// DEMO EQUITY URL : https://demo.equityfundingscript.com/api/v1.1/
// DEV REAL ESTATE URL : https://realapi.groupfund.me/api/v1.1/
// DEMO REAL ESTATE URL : https://realestate.equityfundingscript.com/api/v1.1/

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/`,
});

instance.interceptors.request.use(function(config) {
  const token = localStorage.getItem("adminToken");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

export default instance;
