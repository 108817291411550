import { call, put } from "redux-saga/effects";
import {
  getSpamSettingSuccess,
  spamStart,
  spamFail,
  spamCreateSuccess,
  spamUpdateSuccess,
  getSpammersSuccess,
  createSpammerSuccess,
  getSpammerSuccess,
  updateSpammerSuccess,
  bulkUpdateSpammerSuccess,
} from "../../actions/spamSettings/spamSettingsActions";
import { BULK_SPAMMERS_DELETE_API, BULK_SPAMMERS_UPDATE_API, SPAM_SETTING_API, SPAMMERS_API } from "../../actions/apiCollections";
import axios from "../../../config/AxiosConfig";

export function* getSpamSettingSaga() {
  yield put(spamStart());
  try {
    const response = yield axios.get(SPAM_SETTING_API);
    yield put(getSpamSettingSuccess(response.data.data.data));
  } catch (error) {
    yield put(spamFail(error?.response));
  }
}

export function* createSpamSettingSaga(action) {
  yield put(spamStart());
  try {
    const response = yield axios.post(SPAM_SETTING_API, action.spam);
    yield put(spamCreateSuccess, response.data.data.data);
    yield call(getSpamSettingSaga);
  } catch (error) {
    yield put(spamFail(error?.response));
  }
}

export function* updateSpamSettingSaga(action) {
  yield put(spamStart());
  try {
    const response = yield axios.patch(`${SPAM_SETTING_API}${action.spam.id}`, action.spam);
    yield put(spamUpdateSuccess(response.data.data.data));
    yield call(getSpamSettingSaga);
  } catch (error) {
    yield put(spamFail(error?.response));
  }
}

export function* getSpammersSaga(action) {
  yield put(spamStart());
  try {
    const response = yield axios.get(SPAMMERS_API);
    yield put(getSpammersSuccess(response.data.data.data));
  } catch (error) {
    yield put(spamFail(error?.response));
  }
}

export function* createSpammerSaga(action) {
  yield put(spamStart());
  try {
    const response = yield axios.post(SPAMMERS_API, action.spammer);
    yield put(createSpammerSuccess(response.data.data.data));
    yield call(getSpammersSaga);
  } catch (error) {
    yield put(spamFail(error?.response));
  }
}

export function* updateSpammerSaga(action) {
  yield put(spamStart());
  try {
    const response = yield axios.patch(SPAMMERS_API + action.spammer.id, action.spammer);
    yield put(updateSpammerSuccess(response.data.data.data));
    yield call(getSpammersSaga);
  } catch (error) {
    yield put(spamFail(error?.response));
  }
}

export function* deleteSpammerSaga(action) {
  yield put(spamStart());
  try {
    yield axios.delete(SPAMMERS_API + action.id);
    yield call(getSpammersSaga);
  } catch (error) {
    yield put(spamFail(error?.response));
  }
}

export function* bulkUpdateSpammerSaga(action) {
  yield put(spamStart());
  try {
    const response = yield axios.post(BULK_SPAMMERS_UPDATE_API, action.spammer);
    yield put(bulkUpdateSpammerSuccess(response.data.data.data));
    yield call(getSpammersSaga);
  } catch (error) {
    yield put(spamFail(error?.response));
  }
}

export function* bulkDeleteSpammerSaga(action) {
  yield put(spamStart());
  try {
    yield axios.post(BULK_SPAMMERS_DELETE_API, action.spammer);
    yield call(getSpammersSaga);
  } catch (error) {
    yield put(spamFail(error?.response));
  }
}

export function* getSpammerSaga(action) {
  yield put(spamStart());
  try {
    const response = yield axios.get(SPAMMERS_API + action.id);
    yield put(getSpammerSuccess(response.data.data.data));
  } catch (error) {
    yield put(spamFail(error?.response));
  }
}
