import { put, call } from "redux-saga/effects";
import axios from "../../config/AxiosConfig";
import {
  settingsStart,
  getGeneralSettingsSuccess,
  createGeneralSettingsSuccess,
  updateGeneralSettingsSuccess,
  getCampaignSettingsSuccess,
  settingsFail,
  createCampaignSettingsSuccess,
  updateCampaignSettingsSuccess,
  getAllUsersContactEnquirySuccess,
  getNewsletterSettingsSuccess,
  updateNewsletterSettingsSuccess
} from "../actions/index";
import {
  SITE_SETTINGS_API,
  CAMPAIGN_SETTING_API,
  USERS_CONTACT_LIST_API,
  NEWSLETTER_LIST_API
} from "../actions/apiCollections";

export function* getFrontSiteSettingsSaga() {
  yield put(settingsStart());
  try {
    const response = yield axios.get(`${SITE_SETTINGS_API}/front`, {
      params: { status: true },
    });
    yield put(getGeneralSettingsSuccess(response.data.data.data));
  } catch (error) {
    yield put(settingsFail(error.response));
  }
}

export function* getSiteSettingsSaga() {
  yield put(settingsStart());
  try {
    const response = yield axios.get(SITE_SETTINGS_API, {
      params: { status: true },
    });
    yield put(getGeneralSettingsSuccess(response.data.data.data));
  } catch (error) {
    yield put(settingsFail(error.response));
  }
}

export function* createSiteSettingsSaga(action) {
  try {
    const response = yield axios.post(
      SITE_SETTINGS_API,
      action.generalSettings
    );
    yield put(createGeneralSettingsSuccess(response));
  } catch (error) {
    yield put(settingsFail(error.response));
  }
}

export function* updateSiteSettingsSaga(action) {
  let id = new FormData();
  id = action.generalSettings;
  id = id.get("id");
  try {
    const response = yield axios.patch(
      `${SITE_SETTINGS_API}/${id}`,
      action.generalSettings
    );
    yield put(updateGeneralSettingsSuccess(response));
  } catch (error) {
    yield put(settingsFail(error.response));
  }
}

export function* getCampaignSettings() {
  yield put(settingsStart());
  try {
    const response = yield axios.get(`${CAMPAIGN_SETTING_API}?lng=en`);
    yield put(getCampaignSettingsSuccess(response.data.data.data));
  } catch (error) {
    yield put(settingsFail(error.response.data));
  }
}

export function* createCampaignSettingsSaga(action) {
  try {
    const response = yield axios.post(
      `${CAMPAIGN_SETTING_API}?lng=en`,
      action.campaign
    );
    yield put(createCampaignSettingsSuccess(response.data.data.data));
    yield call(getCampaignSettings);
  } catch (error) {
    yield put(settingsFail(error.response.data));
  }
}

export function* updateCampaignSettingsSaga(action) {
  let id = new FormData();
  id = action.campaign;
  id = id.get("id");
  try {
    const response = yield axios.patch(
      `${CAMPAIGN_SETTING_API}${id}?lng=en`,
      action.campaign
    );
    yield put(updateCampaignSettingsSuccess(response.data.data.data));
    yield call(getCampaignSettings);
  } catch (error) {
    yield put(settingsFail(error.response.data));
  }
}

export function* getAllUsersContactEnquirySaga(action) {
  yield put(settingsStart());
  try {
    const response = yield axios.get(`${USERS_CONTACT_LIST_API}`, {
      params: { limit: 10 * action.response },
    });
    yield put(getAllUsersContactEnquirySuccess(response.data.data.data));
  } catch (error) {
    yield put(settingsFail(error.response.data));
  }
}


export function* getNewsletterSettingsSaga() {
	try {
		const response = yield axios.get(`${NEWSLETTER_LIST_API}`);
		yield put(getNewsletterSettingsSuccess(response.data.data.data));
	} catch (error) {
		console.log(error);
		// yield put(authRegistrationFail(error.response.data.errors[0].msg));
	}
}

export function* updateNewsletterSettingsSaga(action) {
	try {
		const response = yield axios.patch(
      `${NEWSLETTER_LIST_API}${action.id}`,
      action.newsletter
    );
		yield put(updateNewsletterSettingsSuccess(response.data.data.data));
	} catch (error) {
		console.log(error);
	}
}