export const CAMPAIGN_START = "[CAMPAIGN COMPONENT] Campaign Start";
export const CAMPAIGN_FAIL = "[CAMPAIGN COMPONENT] Campaign Fail";
export const GET_CAMPAIGNS = "[CAMPAIGN COMPONENT] Get Campaigns";
export const GET_SEARCH_CAMPAIGNS = "[CAMPAIGN COMPONENT] GET_SEARCH_CAMPAIGNS";
export const GET_CAMPAIGNS_SUCCESS = "[CAMPAIGN COMPONENT] Get Campaigns Success";
export const GET_TOTAL_RESERVARTIONS = "[CAMPAIGN COMPONENT] Get Total Reservations";
export const GET_TOTAL_RESERVARTIONS_SUCCESS = "[CAMPAIGN COMPONENT] Get Total Reservations Success";
export const GET_CAMPAIGN_DETAILS = "[CAMPAIGN COMPONENT] Get Campaign Details";
export const GET_CAMPAIGN_DETAILS_SUCCESS = "[CAMPAIGN COMPONENT] Get Campaign Details Success";
export const GET_INVESTORS = "[CAMPAIGN COMPONENT] Get Investors";
export const GET_INVESTORS_SUCCESS = "[CAMPAIGN COMPONENT] Get Investors Success";
export const GET_INVESTOR = "[CAMPAIGN COMPONENT] Get Investor";
export const GET_INVESTOR_SUCCESS = "[CAMPAIGN COMPONENT] Get Investor Success";
export const GET_DOCUMENTS = "[CAMPAIGN COMPONENT] Get Documents";
export const GET_DOCUMENTS_SUCCESS = "[CAMPAIGN COMPONENT] Get Documents Success";
export const CREATE_CONTRACT_DOCUMENT = "[CAMPAIGN COMPONENT] Create Contract Document";
export const APPROVE_CAMPAIGN = "[CAMPAIGN COMPONENT] Approve Campaign";
export const PENDING_CAMPAIGN = "[CAMPAIGN COMPONENT] Pending Campaign";
export const DECLINE_CAMPAIGN = "[CAMPAIGN COMPONENT] Deny Campaign";
export const HIDE_CAMPAIGN = "[CAMPAIGN COMPONENT] Hide Campaign";
export const INACTIVE_CAMPAIGN = "[CAMPAIGN COMPONENT] Inactive Campaign";
export const FEATURE_CAMPAIGN = "[CAMPAIGN COMPONENT] Feature Campaign";
export const UNFEATURE_CAMPAIGN = "[CAMPAIGN COMPONENT] Unfeature Campaign";
export const FEATURE_CAMPAIGN_SUCCESS = "[CAMPAIGN COMPONENT] Feature Campaign Success";
export const DELETE_CAMPAIGN = "[CAMPAIGN COMPONENT] Delete Campaign";
export const GET_INVESTOR_DETAILS = "[CAMPAIGN COMPONENT] Get Investor Details";
export const GET_INVESTOR_DETAILS_SUCCESS = "[CAMPAIGN COMPONENT] Get Investor Details Success";
export const GET_CAMPAIGN_ACTIVITIES = "[CAMPAIGN COMPONENT] Get Campaign Activities";
export const GET_CAMPAIGN_ACTIVITIES_SUCCESS = "[CAMPAIGN COMPONENT] Get Campaign Activities Success";
export const GET_CAMPAIGN_BONUS_PERKS = "[CAMPAIGN] Get Bonus Perk";
export const GET_CAMPAIGN_BONUS_PERKS_SUCCESS = "[CAMPAIGN] Get Bonus Perk Success";
export const APPROVE_REPAYMENT = "[CAMPAIGN] Approve Repayment";
export const GET_REPAYMENT_IN = "[CAMPAIGN] Get Repayment In";
export const GET_REPAYMENT_IN_SUCCESS = "[CAMPAIGN] Get Repayment In Success";
export const CREATE_REPAYMENT_IN = "[CAMPAIGN] Create Repayment In";
export const UPDATE_REPAYMENT = "[CAMPAIGN] Update Repayment";
export const GET_DISTRIBUTION = "[CAMPAIGN]] Get Distribution";
export const GET_DISTRIBUTION_SUCCESS = "[CAMPAIGN] Get Distribution Success";
export const GET_DISTRIBUTION_DETAIL = "[CAMPAIGN] Get Distribution Detail";
export const GET_DISTRIBUTION_DETAIL_SUCCESS = "[CAMPAIGN] Get Distribution Detail Success";
export const CREATE_DISTRIBUTION = "[CAMPAIGN] Create Distribution";
export const DELETE_DISTRIBUTION = "[CAMPAIGN] Delete Distribution";
export const UPDATE_DISTRIBUTION = "[CAMPAIGN] Update Distribution";
export const UPDATE_DISTRIBUTION_ADJUSTMENT = "[CAMPAIGN] Update Distribution Adjustment";
export const MARK_DISTRIBUTED = "[CAMPAIGN] Mark Distributed";
export const SET_CAMPAIGN_SUCCESS = "[CAMPAIGN] Set Campaign Success";
export const SET_CAMPAIGN_SUCCESS_SUCCESS = "[CAMPAIGN] Set Campaign Success SUCCESS";
export const REMOVE_CAMPAIGN = "[CAMPAIGN] Remove Campaign";
export const IS_LOADING = "[GLOBAL] IS LOADING";
export const INVEST_ACTION = "[CAMPAIGN] Invest Action";
export const INVEST_ACTION_RESPONSE = "[CAMPAIGN] Invest Action Response";
export const CONTRACT_ACTION = "[CAMPAIGN] Contraction Action";
export const CONTRACT_ACTION_RESPONSE = "[CAMPAIGN] Contraction Action Response";
export const ACK_CONTRACT_ACTION = "[CAMPAIGN] Ack. Contract Action";
export const ACK_CONTRACT_ACTION_RESPONSE = "[CAMPAIGN] Ack. Contract Action Response";
export const UPDATE_CAMPAIGN = "[CAMPAIGN] Update Campaign";
export const UPDATE_CAMPAIGN_SUCCESS = "[CAMPAIGN] Update Campaign Success";

/// ACH
export const CLEAR_STRIPE_ACH_PAYMENT_TOKEN = "CLEAR_STRIPE_ACH_PAYMENT_TOKEN";
export const GET_STRIPE_ACH_PAYMENT_TOKEN = "GET_STRIPE_ACH_PAYMENT_TOKEN";
export const GET_STRIPE_ACH_PAYMENT_TOKEN_SUCCESS = "GET_STRIPE_ACH_PAYMENT_TOKEN_SUCCESS";

export const CLEAR_STRIPE_ACH_PAYMENT_CONFIMATION = "CLEAR_STRIPE_ACH_PAYMENT_CONFIMATION";
export const POST_STRIPE_ACH_PAYMENT_CONFIMATION = "POST_STRIPE_ACH_PAYMENT_CONFIMATION";
export const POST_STRIPE_ACH_PAYMENT_CONFIMATION_SUCCESS = "POST_STRIPE_ACH_PAYMENT_CONFIMATION_SUCCESS";
export const CLEAR_ONE_TIME_ERROR = "CLEAR_ONE_TIME_ERROR";
export const GET_PRIVATE_CAMPAIGN_USERS = "GET_PRIVATE_CAMPAIGN_USERS";
export const GET_PRIVATE_CAMPAIGN_USERS_SUCCESS = "GET_PRIVATE_CAMPAIGN_USERS_SUCCESS";
export const ADD_PRIVATE_CAMPAIGN_USER = "ADD_PRIVATE_CAMPAIGN_USER";
export const ADD_PRIVATE_CAMPAIGN_USER_SUCCESS = "ADD_PRIVATE_CAMPAIGN_USER_SUCCESS";
export const DELETE_PRIVATE_CAMPAIGN_USER = "DELETE_PRIVATE_CAMPAIGN_USER";
export const EMPTY_EMAIL_MSG = "EMPTY_EMAIL_MSG";
export const RESEND_MAIL_TO_CAMPAIGN_USER = "RESEND_MAIL_TO_CAMPAIGN_USER";
export const RESEND_MAIL_TO_CAMPAIGN_USER_SUCCESS = "RESEND_MAIL_TO_CAMPAIGN_USER_SUCCESS";
